import React from 'react';

import Layout from '../layouts/index';

const PrivacyPage = props => (
  <Layout {...props}>
    <div className="container">
      <br />
      <br />
      <br />
      <h1>Datenschutzerklärung</h1> <h3>Datenschutz</h3>
      <p>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
        sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung. Die Nutzung unserer Website ist in der Regel ohne
        Angabe personenbezogener Daten m&ouml;glich. Soweit auf unseren Seiten
        personenbezogene Daten (beispielsweise Name, Anschrift oder
        E-Mail-Adressen) erhoben werden, erfolgt dies, soweit m&ouml;glich,
        stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
        ausdr&uuml;ckliche Zustimmung nicht an Dritte weitergegeben. Wir weisen
        darauf hin, dass die Daten&uuml;bertragung im Internet (z.B. bei der
        Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein
        l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
        m&ouml;glich.
      </p>
      <p>&nbsp;</p> <h3>Server-Log- Files</h3>{' '}
      <p>
        Der Provider der Seiten erhebt und speichert automatisch Informationen
        in so genannten Server-Log Files, die Ihr Browser automatisch an uns
        &uuml;bermittelt. Dies sind:
      </p>{' '}
      <ul>
        <li>Browsertyp und Browserversion</li>{' '}
        <li>verwendetes Betriebssystem</li> <li>Referrer URL</li>{' '}
        <li>Hostname des zugreifenden Rechners</li>{' '}
        <li>Uhrzeit der Serveranfrage</li>{' '}
      </ul>{' '}
      <p>
        Diese Daten sind nicht bestimmten Personen zuordenbar. Eine
        Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird nicht
        vorgenommen. Wir behalten uns vor, diese Daten nachtr&auml;glich zu
        pr&uuml;fen, wenn uns konkrete Anhaltspunkte f&uuml;r eine rechtswidrige
        Nutzung bekannt werden.
      </p>
      <p>&nbsp;</p> <h3>Kontaktformular</h3>{' '}
      <p>
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
        Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
        Kontaktdaten zwecks Bearbeitung der Anfrage und f&uuml;r den Fall von
        Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne
        Ihre Einwilligung weiter.
      </p>
      <p>&nbsp;</p> <h3>Google Analytics</h3>{' '}
      <p>
        Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics.
        Anbieter ist die Google Inc., 1600 Amphitheatre Parkway Mountain View,
        CA 94043, USA.
      </p>{' '}
      <p>
        Google Analytics verwendet so genannte &quot;Cookies&quot;. Das sind
        Textdateien, die auf Ihrem Computer gespeichert werden und die eine
        Analyse der Benutzung der Website durch Sie erm&ouml;glichen. Die durch
        den Cookie erzeugten Informationen &uuml;ber Ihre Benutzung dieser
        Website werden in der Regel an einen Server von Google in den USA
        &uuml;bertragen und dort gespeichert.
      </p>{' '}
      <p>
        <strong>Browser Plugin</strong>
      </p>{' '}
      <p>
        Sie k&ouml;nnen die Speicherung der Cookies durch eine entsprechende
        Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
        darauf hin, dass Sie in diesem Fall gegebenenfalls nicht s&auml;mtliche
        Funktionen dieser Website vollumf&auml;nglich werden nutzen k&ouml;nnen.
        Sie k&ouml;nnen dar&uuml;ber hinaus die Erfassung der durch den Cookie
        erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
        IP- Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
        verhindern, indem Sie das unter dem folgenden Link verf&uuml;gbare
        Browser-Plugin herunterladen und installieren:{' '}
        <a
          href="https://tools.google.com/dlpage/gaoptout?hl=de"
          target="_blank"
        >
          https://tools.google.com/dlpage/gaoptout?hl=de
        </a>
      </p>{' '}
      <p>
        <strong>Widerspruch gegen Datenerfassung</strong>
      </p>{' '}
      <p>
        Sie k&ouml;nnen die Erfassung Ihrer Daten durch Google Analytics
        verhindern, indem Sie auf folgenden Link klicken. Es wird ein
        Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei
        zuk&uuml;nftigen Besuchen dieser Website verhindert:{' '}
        <a href="javascript:gaOptout();">Google Analytics deaktivieren</a>
      </p>{' '}
      <p>
        Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
        finden Sie in der Datenschutzerkl&auml;rung von Google:
        <a
          href="https://support.google.com/analytics/answer/6004245?hl=de"
          target="_blank"
        >
          https://support.google.com/analytics/answer/6004245?hl=de
        </a>
      </p>
      <p>
        <strong>Demografis Merkmale bei Google Analytics</strong>
      </p>{' '}
      <p>
        Diese Website nutzt die Funktion &ldquo;demografische Merkmale&rdquo;
        von Google Analytics. Dadurch k&ouml;nnen Berichte erstellt werden, die
        Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher
        enthalten. Diese Daten stammen aus interessenbezogener Werbung von
        Google sowie aus Besucherdaten von Drittanbietern. Diese Daten
        k&ouml;nnen keiner bestimmten Person zugeordnet werden. Sie k&ouml;nnen
        diese Funktion jederzeit &uuml;ber die Anzeigeneinstellungen in Ihrem
        Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch Google
        Analytics wie im Punkt &ldquo;Widerspruch gegen Datenerfassung&rdquo;
        dargestellt generell untersagen.
      </p>{' '}
      <p>&nbsp;</p> <h3>SSL-Verschl&uuml;sselung</h3>{' '}
      <p>
        Diese Seite nutzt aus Gr&uuml;nden der Sicherheit und zum Schutz der
        &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen,
        die Sie an uns als Seitenbetreiber senden, eine
        SSL-Verschl&uuml;sselung. Eine che verschl&uuml;sselte Verbindung
        erkennen Sie daran, dass die Adresszeile des Browsers von
        &quot;http://&quot; auf &quot;https://&quot; wechselt und an dem
        Schloss-Symbol in Ihrer Browserzeile.
      </p>{' '}
      <p>
        Wenn die SSL Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten,
        die Sie an uns &uuml;bermitteln, nicht von Dritten mitgelesen werden.
      </p>
      <p>&nbsp;</p>{' '}
      <p>
        Quelle: <a href="https://www.e-recht24.de">eRecht24</a>
      </p>
    </div>
  </Layout>
);

export default PrivacyPage;
